<template>
    <main class="main-content  mt-0">
        <div class="page-header align-items-start min-vh-100">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container my-auto">
                <div class="row">
                    <div class="col-lg-4 col-md-8 col-12 mx-auto">
                        <div class="card z-index-0 fadeIn3 fadeInBottom">
                            <form v-on:submit.prevent="onSubmit">
                                <div class="card-body">
                                    <h4 class="font-weight-bolder text-center mt-2 mb-4">Set PIN </h4>
                                    <label class="form-label">New PIN</label>
                                    <div class="font-weight-light" style="font-size: smaller;" >5-digit Printing PIN</div>
                                    <div class="input-group input-group-outline mb-0 justify-content-center">
                                        <!--<input type="number" inputmode="numeric" v-model="pin" class="form-control" min="5" minlength="5">-->
                                        <pincode-input v-model="pin" :length="5"></pincode-input>
                                    </div>
                                    <div class="text-center">
                                        <custom-primary-button class="w-100  my-4 mb-2"
                                                               type="submit"
                                                               :loading="loading">Set PIN</custom-primary-button>
                                    </div>
                                    <p class="mt-4 text-sm text-center">
                                        Want to Sign In?
                                        <router-link class="text-primary text-gradient font-weight-bold" to="/sign-in">Sign In</router-link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <custom-toast v-model="resetToast.show">
                {{resetToast.message}}
            </custom-toast>
        </div>

    </main>
</template>

<script>
    import { mapActions } from 'vuex'
    import CustomToast from '@/components/custom-toast.vue'
    import CustomPrimaryButton from '@/components/custom-primary-button.vue'
    import PincodeInput from 'vue-pincode-input';
    export default {
        components: {
            CustomToast,
            CustomPrimaryButton,
            PincodeInput
        },
        props: {
            setToken: String
        },
        data() {
            return {
                email: '',
                pin: null,
                loading: false,
                resetToast: {
                    message: 'PIN Reset, Please check your institution email.',
                    show: false
                }
            }
        },
        methods: {
            ...mapActions(['setAuth']),
            notification: function (message) {
                this.resetToast.message = message;
                this.resetToast.show = true;
            },
            setPin: async function () {
                if (this.loading) {
                    this.notification('Already busy')
                    return;
                }

                if (this.pin.length < 5 || this.pin.length > 5) {
                    this.notification('Your printing pin must be only 5-digits');
                    return;
                }
                this.loading = true;

                let result = await this.$http.post('/api/Auth/SetPin', { email: this.email, pin: this.pin, token: this.setToken })
                
                console.log(result)
                if (result.status == 200) {
                    this.setAuth(result.data)
                    this.$router.push({ name: 'home' })
                }
                else if (result.status == 400){
                    this.notification('Something went wrong, your token might have expired. Please request a new reset mail.')
                }

                this.loading = false;
            },
            onSubmit: async function () {
                await this.setPin();
            }
        }
    }
</script>
